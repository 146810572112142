import userInfo from 'hub-http/userInfo';
let __prevGates;
let __gatesSet;
const convertToSet = gates => {
  if (!__gatesSet || gates !== __prevGates) {
    __gatesSet = new Set(gates);
  }
  return __gatesSet;
};
export const hasGate = async gateName => {
  try {
    // WARN: ignoreRedirect is important! Without it, any consumers that don't
    // support or shim userInfo can end up redirecting (the default behavior).
    // This has caused past critsits:
    // https://git.hubteam.com/HubSpot/Critsit/issues/3557
    // https://git.hubteam.com/HubSpot/Critsit/issues/1040
    const {
      gates
    } = await userInfo({
      ignoreRedirect: true
    });
    return convertToSet(gates).has(gateName);
  } catch (e) {
    return false;
  }
};