import { OrderedSet, fromJS } from 'immutable';
import getIn from 'transmute/getIn';

// @ts-expect-error untyped import
import FilterOperatorEnumInput from 'customer-data-filters/components/operator/FilterOperatorEnumInput';
// @ts-expect-error untyped import
import * as Operators from 'customer-data-filters/filterQueryFormat/operator/Operators';
import * as OperatorTypes from 'customer-data-filters/converters/contactSearch/FilterContactSearchOperatorTypes';
// @ts-expect-error untyped import
import { IN_LIST } from 'customer-data-filters/converters/objectSeg/ObjectSegFilterTypes';
import { IS_IN_LIST, IS_NOT_IN_LIST } from 'customer-data-filters/converters/objectSeg/ObjectSegOperatorTypes';
import { HUBSPOT_OBJECT_COORDINATES_TO_DATA_TYPE } from 'reporting-data/constants/objectCoordinates';
import { CONTACTS } from 'reporting-data/constants/dataTypes/inboundDb';
import { getObjectTypeFamily, getLabelString
// @ts-expect-error untyped import
} from 'reporting-snowflake/relational/modify/filters/xo-filter-utils';
import { getInboundDbListType } from 'reporting-snowflake/relational/modify/filters/list-membership-utils';
import { Conditions } from 'reporting-snowflake/relational/schema/filter-records';
import { getName } from './schema/propertySchema';
export const LEGACY_LIST_MEMBERSHIP_PROPERTY_NAME = 'listMemberships.listId';
export const LIST_MEMBERSHIP_PROPERTY_NAME = 'ilsListMemberships.listId';
export const isListMembershipProperty = propertyName => {
  return propertyName === LEGACY_LIST_MEMBERSHIP_PROPERTY_NAME || propertyName === LIST_MEMBERSHIP_PROPERTY_NAME;
};
export const isListMembershipFilter = filter => {
  return getIn(['filter', 'filterType'])(filter) === IN_LIST;
};
export const isContactsDataSource = dataSource => {
  return HUBSPOT_OBJECT_COORDINATES_TO_DATA_TYPE.get(dataSource) === CONTACTS;
};

// The legacy list membership property name ('listMemberships.listId') is deprecated,
// but still in use for SO based reports. This function updates
// the property name to 'ilsListMemberships.listId' have the dashboard filter
// compatible with SO reports, since it is supported OOTB for SO reports.
export const maybeUpdateLegacyListMembershipOperatorName = operator => {
  const maybeUpdatedPropertyName = operator.property && operator.property === LEGACY_LIST_MEMBERSHIP_PROPERTY_NAME ? LIST_MEMBERSHIP_PROPERTY_NAME : operator.property;
  return Object.assign({}, operator, {
    property: maybeUpdatedPropertyName
  });
};

// CONTACTS based SO reports use the legacy property name for the List membership property. This function
// converts its name to the ILS (non-legacy) version.
export const maybeUpdateLegacyListMembershipPropertyName = property => {
  const propertyName = property.get('name');
  const maybeUpdatedProperty = propertyName === LEGACY_LIST_MEMBERSHIP_PROPERTY_NAME ? property.set('name', LIST_MEMBERSHIP_PROPERTY_NAME) : property;
  return maybeUpdatedProperty;
};

// Reference resolvers resolve to ILS List membership IDs, not the legacy
// List membership IDs.
export const getReferenceTypeForListMembershipProperty = ({
  dataSource,
  property
}) => {
  const propertyName = getName(property);
  const objectTypeFamily = getObjectTypeFamily(dataSource);
  const inboundDbListType = getInboundDbListType(objectTypeFamily);
  return isListMembershipProperty(propertyName) ? inboundDbListType : undefined;
};
export const getInputComponentListMembership = () => {
  return FilterOperatorEnumInput;
};
export const getOperatorsListMembership = () => OrderedSet.of(Operators.In, Operators.NotIn);

// reference getMappedProps in reporting/reporting-snowflake/static-1.40849/js/relational/modify/filters/components/list-filter/ListFilterPanel
export const getMappedPropsListMembership = ({
  dataSource,
  property,
  resolvers
}) => {
  return {
    currencyCode: 'USD',
    filterFamily: dataSource,
    getFamilyValueResolver: () => resolvers[getReferenceTypeForListMembershipProperty({
      dataSource,
      property
    })],
    getLabelString: () => getLabelString(property),
    getReferencedObjectType: () => getReferenceTypeForListMembershipProperty({
      dataSource,
      property
    }),
    getSpecialOptionsForReferenceType: () => undefined,
    isXoEnabled: true
  };
};
export const listMembershipsToObjectSegFilterGroup = (dataSourceName, operator, idCounter) => {
  const operatorName = operator.get('name');
  const Operator = Operators[operatorName];
  const listIds = operator.get('value').toJS();
  const listMembershipFilters = listIds.map(listId => {
    // "IN" translates to a series of "IS_IN_LIST" operators OR-ed together.
    // "NOT_IN" translates to a series of "IS_NOT_IN_LIST" operators AND-ed together
    const listMembershipFilter = {
      field: {
        name: 'objectid',
        table: dataSourceName,
        type: 'number'
      },
      filter: {
        filterType: IN_LIST,
        listId,
        operator: Operator === Operators.In ? IS_IN_LIST : IS_NOT_IN_LIST
      }
    };
    return listMembershipFilter;
  });
  const newListMembershipFilterGroup = fromJS({
    id: idCounter,
    condition: Operator === Operators.In ? Conditions.OR : Conditions.AND,
    filters: listMembershipFilters
  });
  return newListMembershipFilterGroup;
};
export const listMembershipsFilterToContactSearch = (dataSource, listMembershipFilter) => {
  const listMembershipFilterOperator = getIn(['filter', 'operator'])(listMembershipFilter);
  const listMemershipFilterValue = getIn(['filter', 'listId'])(listMembershipFilter);

  // CONTACTS based SO reports use the legacy property name for the List membership property.
  const listMembershipContactSearchFormat = {
    operator: listMembershipFilterOperator === IS_IN_LIST ? OperatorTypes.IN : OperatorTypes.NOT_IN,
    property: LIST_MEMBERSHIP_PROPERTY_NAME,
    values: [listMemershipFilterValue]
  };
  return listMembershipContactSearchFormat;
};