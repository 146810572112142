import { gate } from 'hub-http/gates';
export const SRV_PSEUDO_STORY_REPORTS = 'ReportingEnablement:summaryv2';
export const CRM_SEARCH_FILTERS_V2 = 'ReportingEnablement:FiltersV2';
export const REPORTING_AI_INSIGHTS_SLIDER_GATE = 'reportingAI:insightSlider';
export const USE_VALID_INSIGHTS_API_FOR_TRENDLINES = 'RAAS:UseValidInsightsAPI';
export const ENABLE_OR_FILTERS = 'ReportingEnablement:EnableOrFilters';
export const DATASET_IN_SRV = 'RE:DatasetInSRV';
export const SRV_LITE_EDITTING_GATE = 'RE:SRVLiteEdittingGate';
export const DATA_QUALITY_INSIGHTS_GATE = 'DataQuality:ReportViewerInsights';
export const AI_ACCESS_GATE = 'AIComponents:AISettings';
export const DEALS_CREATED_SRV_MIGRATION = gate('RE:DealsCreatedSRVMigration');
export const ENABLE_DATE_PROPERTY_FOR_ALL_CONFIG_REPORTS = gate('ReportingEnablement:EnableDatePropertyForAllConfigReports');
export const REPORT_CARD_V2_SRV = gate('RE:ReportCardV2:SRV');
export const getIsUngatedForSRVLiteEditting = (gates = []) => {
  return gates.includes(gate('RE:SRVLiteEdittingGate'));
};
export const getIsUngatedForDQReportViewerInsights = gates => {
  return gates === null || gates === void 0 ? void 0 : gates.includes(gate('DataQuality:ReportViewerInsights'));
};