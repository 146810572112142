import { makeFrameworkTypesQuickFetchKey, makeFrameworkTypesFetchUrl, ObjectLibraryFetchGate } from 'framework-data-schema-quick-fetch';
import { withQuickFetch } from 'framework-data-schema-quick-fetch/withQuickFetch';
import { hasGate } from '../auth/hasGate';
export const fetchFrameworkTypes = ({
  family,
  httpClient
}) => withQuickFetch({
  requestName: makeFrameworkTypesQuickFetchKey({
    family
  }),
  baseFetch: () => hasGate(ObjectLibraryFetchGate).then(ungated => httpClient.get(makeFrameworkTypesFetchUrl({
    family,
    hasObjectLibraryGate: ungated
  })))
});